<template>
    <div class="footer_contents">
        <div class="logo_container">
            <router-link to="/" aria-label="Go to home page">
                <img :src="logo" class="logo" alt="Cerda-Zein Logo"/>
            </router-link>
        </div>
        <div class="contact_container">
            <div class="social_icons">
                <a aria-label="Go to Cerda Zein Yelp Home Page" href="https://www.yelp.com/biz/cerda-zein-real-estate-alameda-2" target="_blank" class="icon-link">
                    <font-awesome-icon :icon="['fab', `yelp`]" class="icon"/>
                </a>
                <a aria-label="Go to Cerda Zein Facebook Home Page" href="https://www.facebook.com/CerdaZeinRealEstate" target="_blank" class="icon-link">
                    <font-awesome-icon :icon="['fab', `facebook-square`]" class="icon"/>
                </a>
                <a aria-label="Go to Cerda Zein Instagram Home Page" href="https://www.instagram.com/cerdazeinrealestate/" target="_blank" class="icon-link">
                    <font-awesome-icon :icon="['fab', `instagram`]" class="icon"/>
                </a>
            </div>
            <!-- <div class="contacts">
                <a :href="`tel:1.${$t(`message.inquire.phone`)}`" class="btn" :aria-label="`Click to call ${$t(`message.inquire.phone`)}`">
                    {{$t(`message.inquire.phone`)}}
                </a>
                <a :href="`mailto:${$t(`message.inquire.email`)}`" class="btn" :aria-label="`Click to send email to ${$t(`message.inquire.email`)}`">
                Email
                </a>
            </div> -->
        </div>
        <div class="map_container">
            <router-link to="/inquire" aria-label="Go to Inquire Page"><p>{{$t('message.footer.findus')}}</p></router-link>
            <div class="container">
                <img alt="Google map location" v-lazy="`https://maps.googleapis.com/maps/api/staticmap?center=37.763999,-122.240771&zoom=14&markers=icon:https://cerda-zein.com/images/marker_black.png|37.763999,-122.240771
                &size=200x200&key=AIzaSyClcrOhxKRmW9SCnOYIkol5B86zQJRpQoQ&${get_static_style(styles)}`" >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterContents",
    data(){
        return {
            logo:"/images/Logo.jpg",
            marker: {
                lat: 37.763999, 
                lng: -122.240771,
            },
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    featureType: "poi",
                    stylers: [
                        { visibility: "off" }
                    ]
                },
                {
                    featureType: "transit",
                    stylers: [
                        { visibility: "off" }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [
                        {"visibility": "off"}
                    ]
                }
            ]
        }
    },
    methods: {
        get_static_style(styles) {
            var result = [];
            styles.forEach(function(v){
                var style='';
                if (v.stylers.length > 0) { // Needs to have a style rule to be valid.
                    style += (v.hasOwnProperty('featureType') ? 'feature:' + v.featureType : 'feature:all') + '|';
                    style += (v.hasOwnProperty('elementType') ? 'element:' + v.elementType : 'element:all') + '|';
                    v.stylers.forEach(function(val){
                        var propertyname = Object.keys(val)[0];
                        var propertyval = val[propertyname].toString().replace('#', '0x');
                        style += propertyname + ':' + propertyval + '|';
                    });
                }
                result.push('style='+encodeURIComponent(style))
            });

            return result.join('&');
        }
    }
}
</script>

<style lang="scss" scoped>
.footer_contents{
    display: flex;
    flex-wrap: wrap;
    background: #ddd;
    justify-content: center;
    align-items: center;
    padding: 64px 0;
    text-align: center;
    border-bottom: 1px solid #c5c5c5;
}
.logo_container{
    width: 33.33%;
    .logo{
        width: 200px;
    }
}
.contact_container{
    width: 33.33%;
    border-left: 1px solid #c5c5c5;
    border-right: 1px solid #c5c5c5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    .social_icons{
        .icon-link{
            margin: 0 12px;
        }
        .icon{
            font-size: 1.6rem;
            color: #000;
            &:hover{
                opacity: 0.85;
            }
        }
    }
    .contacts{
        display:flex;
        flex-direction: column;
        a{
            margin: 16px 0;
        }
    }
}
.map_container{
    width: 33.33%;
    margin: 0 auto;
    display:flex;
    flex-direction: column;
    margin-top: -56px;
    p{
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.2px;
        font-size: 0.75rem;
    }
    .container{
        margin: 0 auto;
        width: 200px;
    }
    a{
        color: #000;
        text-decoration: none;
    }
}

@media (max-width: $mobile) {
    .footer_contents{
        display: block;
    }
    .logo_container{
        width: 100%;
    }
    .contact_container{
        margin: 32px auto;
        width: 80%;
        border: none;
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;

    }
    .map_container{
        width: 100%;
        margin-top: 0;
    }
}

</style>
